// src/httpClient.js
import axios from 'axios';

const httpClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const setAuthToken = (token) => {
    if (token) {
        httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete httpClient.defaults.headers.common['Authorization'];
    }
};

export default httpClient;
