<template>
    <footer v-if="user && user.tp_number" class="page-footer bg-primary" :class="{'has--save-button': isSaveableRoute}">
        <div v-if="isSaveableRoute">
            <button class="btn btn-primary btn-lg btn-save" @click="emitSave">
                Save
            </button>
        </div>
        <div v-else>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-4">
                        <a href="#" class="action-bar-item d-flex flex-column align-items-center" @click="copyInviteLink">
                            <img src="@/assets/images/action-bar/einladungslink.png" alt="Copy invite link" class="img-fluid">
                            <small>Invite link</small>
                        </a>
                    </div>

                    <div class="col-4">
                        <a href="#" class="action-bar-item d-flex flex-column align-items-center" @click="showInviteModal = true">
                            <img src="@/assets/images/action-bar/qr-einladung.png" alt="Show QR-Invitation" class="img-fluid">
                            <small>Invite code</small>
                        </a>
                    </div>

                    <div class="col-4">
                        <a :href="'https://crossworx.shop/crossworx/start?tpn=' + user.tp_number" target="_blank" class="action-bar-item d-flex flex-column align-items-center">
                            <img src="@/assets/images/action-bar/cwx-shop.png" alt="crossworx Shop" class="img-fluid">
                            <small>crossworx Shop</small>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>


    <modal v-model:showModal="showInviteModal" title="Invitation Code" class="text-center">
        <template #body>
            <div class="mb-3">
                Your personal invitation link as a QR code.
            </div>

            <qr-code
                    class="qr-code"
                    ref="modalQr"
                    :contents="inviteLink"
                    module-color="#111"
                    position-ring-color="#c93a36"
                    position-center-color="#c93a36"
            >
                <!-- eslint-disable-next-line vue/no-deprecated-slot-attribute -->
                <img src="@/assets/images/brand/x.svg" alt="qr" slot="icon" class="qr-logo" />
            </qr-code>

            <div>
                By scanning this QR code, you can invite members to your network.
            </div>
        </template>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification';
import Modal from "@/views/components/ModalComponent.vue";

export default {
    name: 'ActionBarLayout',
    components: {Modal},

    data() {
      return {
          showInviteModal: false
      }
    },

    computed: {
        ...mapGetters(['user']),
        isSaveableRoute() {

            const saveableRoutes = [
                '/profile'
            ];

            return saveableRoutes.includes(this.$route.path);

        },
        inviteLink() {
            return process.env.VUE_APP_CWX_BASE_URL + "/registrierung/token/" + this.user.token
        }
    },

    methods: {
        emitSave() {
            this.$emit('save-button-clicked');
        },
        copyInviteLink() {

            const link = this.inviteLink;
            navigator.clipboard.writeText(link);

            const toast = useToast();
            toast.success('Copied invite link!', {
                position: "top-center",
                timeout: 2000,
                showCloseButtonOnHover: true,
            });
        }
    }
}
</script>