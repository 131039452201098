import { createStore } from 'vuex';
import httpClient, { setAuthToken } from '@/httpClient';

export default createStore({
    state: {
        user: null,
        token: null,
        loginError: null,
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
        },
        SET_TOKEN(state, token) {
            state.token = token;
        },
        LOGOUT(state) {
            state.user = null;
            state.token = null;
            // Remove token from localStorage
            localStorage.removeItem('authToken');
            // Clear the token from HTTP client
            setAuthToken(null);
            window.location.reload();
        },
        SET_LOGIN_ERROR(state, error) {
            state.loginError = error;
        },
    },
    actions: {
        async login({ commit }, credentials) {
            try {
                const response = await httpClient.post('/pwa/login', credentials);
                const token = response.data.data.token;
                const user = response.data.data.user;

                // Save token and user to the Vuex state
                commit('SET_TOKEN', token);
                commit('SET_USER', user);

                // Save token to localStorage
                localStorage.setItem('authToken', token);

                // Set the token for HTTP client
                setAuthToken(token);
                commit('SET_LOGIN_ERROR', null);
            } catch (error) {
                console.error('Error logging in:', error);
                commit('SET_LOGIN_ERROR', 'Login error. Please check your credentials and try again.');
            }
        },
        logout({ commit }) {
            // Clear token and user from Vuex state
            commit('LOGOUT');
        },
        async initializeAuth({ commit }) {
            // Get the token from localStorage
            const token = localStorage.getItem('authToken');
            if (token) {
                // Set the token for HTTP client
                setAuthToken(token);
                commit('SET_TOKEN', token);

                let response = {};
                let user = {};
                try {
                    response = await httpClient.get('/pwa/partners/me');
                    if (response) {
                        user = response.data.data;
                    }
                } catch {
                    // Token probably expired
                }

                if (Object.keys(user).length > 0) {
                    commit('SET_USER', user);
                } else {
                    commit('LOGOUT');
                }
            }
        },
    },
    getters: {
        user(state) {
            return state.user ?? {};
        },
        loginError(state) { // Add this getter
            return state.loginError;
        },
    },
});
