<template>
    <div class="accordion" :class="classes.split(' ')" :id="id">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'AccordionComponent',
    props: {
        id: {
            type: String,
            required: true
        },
        classes: {
            type: String,
            required: false,
            default: ""
        }
    }
}
</script>