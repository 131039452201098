import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

import '@/assets/scss/base.scss'
import 'bootstrap/dist/js/bootstrap.bundle'

import VueSplide from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import PullToRefresh from 'pulltorefreshjs';
import {Howl} from 'howler';
import pageRefreshSound from '@/assets/audio/page_refresh.mp3'

const sound = new Howl({
    src: [`${pageRefreshSound}`],
    html5: true,
    onend: function() {
        window.location.reload();
    }
});

const app = createApp(App)
app.config.devtools = true
app.use(router)
app.use(store)

// Initialize auth state
store.dispatch('initializeAuth');

// Slider
app.use(VueSplide)

// Toasts
app.use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 5,
    newestOnTop: true,
    filterBeforeCreate: (toast, toasts) => {
        // prevent duplicate toasts
        if (toasts.filter(t => t.type === toast.type).length !== 0) {
            return false;
        }
        return toast;
    }
});

// Pull to refresh
PullToRefresh.init({
    mainElement: 'body',
    instructionsPullToRefresh: 'Zum Aktualisieren ziehen',
    instructionsReleaseToRefresh: 'Zum Aktualisieren loslassen',
    instructionsRefreshing: 'Wird aktualisiert',
    distIgnore: 5,
    onRefresh() {
        sound.play();
    }
});

// Mount app
app.mount('#app')
