<template>
    <div class="accordion-item">
        <h2 class="accordion-header">
            <button class="accordion-button" type="button" :class="{ collapsed: !isOpen }" @click="toggle" :disabled="disabled">
                <slot name="header-button"></slot>
            </button>
        </h2>
        <div :id="id" class="accordion-collapse collapse" :class="{ show: isOpen }" :data-bs-parent="parentId">
            <div class="accordion-body">
                <slot name="body" v-if="!loading"></slot>
                <template v-else>
                    <div class="loader"></div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccordionItemComponent',
    props: {
        id: {
            type: String,
            required: true
        },
        parentId: {
            type: String,
            default: 'accordionExample'
        },
        initiallyOpen: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isOpen: !this.disabled && this.initiallyOpen,
            loading: false
        };
    },
    methods: {
        toggle() {
            if (this.disabled) {
                return;
            }

            this.isOpen = !this.isOpen;
            this.$emit('accordion-' + (this.isOpen ? 'opened' : 'closed'), this);
        }
    }
}
</script>