<template>
    <h3 v-if="!opened" class="fw-bold">storelocator</h3>
    <div class="map-container" :class="{ 'map-fullscreen' : opened }">
        <div class="map-header">
            <span class="h3">storelocator</span>
            <button type="button" class="btn-close" @click="opened = false" aria-label="Close"></button>

        </div>
        <div class="search-container" v-if="opened">
            <p class="h3 fw-bold text-dark">storelocator</p>
            <div class="input-group mb-3">
                <input
                    ref="searchInput"
                    v-model="searchQuery"
                    @focus="handleInputFocus"
                    type="text"
                    placeholder="Enter search term"
                    class="form-control"
                >
                <button class="btn btn-primary" @click="handleSearch">Search</button>
            </div>

            <div class="d-grid">
                <button class="btn btn-secondary" @click="resetSearch">Show all</button>
            </div>

            <div v-if="noStoresFoundMessage" class="no-stores-found-message">
                {{ noStoresFoundMessage }}
            </div>
        </div>
        <div id="map" style="height: 100%; width: 100%;"></div>
        <div class="map-overlay" @click="opened = true"></div>
    </div>
</template>

<script>
import httpClient from "@/httpClient";
import { Modal } from 'bootstrap'
export default {
    map: null, // Non-reactive class store

    name: "GoogleMap",
    data() {
        return {
            opened: false,
            loading: false,
            stores: [],
            markers: [],
            userCoordinates: { lat: 47.1487214, lng: 9.5082777 },
            searchQuery: '',
            Map: null,
            LatLngBounds: null,
            noStoresFoundMessage: '',
            searchDebounce: null,
        };
    },
    mounted() {
        // this.setUserCoordinates();
        this.fetchStores();
        this.initMap();
        this.centerMapOnDefaultLocation();
    },
    watch: {
        stores() {
            this.addMarkers();
        }
    },
    computed: {
        center() {
            return this.userCoordinates;
        },
        filteredStores() {
            if (!this.searchQuery) return this.stores;
            const query = this.searchQuery.toLowerCase();
            return this.stores.filter(store =>
                store.company.toLowerCase().includes(query)
                //in case we need to add for search criteria
                // store.company.toLowerCase().includes(query) ||
                // store.place.toLowerCase().includes(query) ||
                // store.zipcode.includes(query)
            );
        }
    },
    methods: {
        async fetchStores() {
          this.loading = true;
            try {
                const response = await httpClient.get('/pwa/partners/active-partner')
                // this.stores = response.data.data
                this.stores = response.data.data.filter(store =>
                    !isNaN(parseFloat(store.lat)) && !isNaN(parseFloat(store.lng))
                );
                this.stores.forEach((element, index) => {
                  element.id = index + 1; // Adding a unique key 'id' with a unique number value
                });
                console.log(this.stores);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
              this.loading = false;
            }
        },
        getImageOrPlaceholderUrl(path) {
            // return path ? process.env.VUE_APP_CWX_BASE_URL + path : require('@/assets/images/profile/default-avatar.png');
            return path ? process.env.VUE_APP_CWX_BASE_URL + path : require('@/assets/images/profile/default-company-avatar.png');
        },
        setUserCoordinates() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.userCoordinates = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        },
                        (error) => {
                          console.error(error);
                          this.error = "Location access denied. Using default location.";
                          // Use default coordinates or prompt user to enter a location
                          if (this.map) {
                            this.map.setCenter(this.userCoordinates);
                          }
                        }
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        },
        async initMap() {
            try {
                // eslint-disable-next-line no-undef
                const { Map } = await google.maps.importLibrary("maps");

                // eslint-disable-next-line no-undef
                const { LatLngBounds } = await google.maps.importLibrary("core");

                this.Map = Map;
                this.LatLngBounds = LatLngBounds;

                const center = this.center;
                this.map = new Map(document.getElementById("map"), {
                    zoom: 5,
                    center,
                    mapId: "cf9ee8764679a2b3",
                    fullscreenControl: false,
                    streetViewControl: false,
                    zoomControlOptions: {
                        // eslint-disable-next-line no-undef
                        position: google.maps.ControlPosition.RIGHT_CENTER,
                    },
                });
                await this.addMarkers();
            } catch (error) {
                console.error("Error initializing map:", error);
            }
        },
        async addMarkers() {
          if (this.markers.length > 0) {
            this.markers.forEach(marker => marker.setMap(null));
            this.markers = [];
          }
          // eslint-disable-next-line no-undef
          const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

          var toShow = this.filteredStores.length===0 ? this.stores : this.filteredStores;
          // this.filteredStores.forEach((store) => {
          toShow.forEach((store) => {
            const lat = parseFloat(store.lat);
            const lng = parseFloat(store.lng);
            if (!isNaN(lat) && !isNaN(lng)) {
              const markerContent = this.buildContent(store);
              const marker = new AdvancedMarkerElement({
                map: this.map,
                content: markerContent,
                position: { lat, lng },
                title: store.description,
              });

              marker.store = store; // Store the store data with the marker

              marker.addListener("click", () => {
                this.toggleHighlight(marker, store);
              });

              this.markers.push(marker);
            } else {
              console.warn(`Invalid coordinates for store: ${store.company}`);
            }
          });
        },
        handleSearch() {
          if (this.searchQuery.trim() === '') {
            this.centerMapOnDefaultLocation();
            // this.addMarkers(); // Show all markers
          } else {
            const matchingStores = this.filteredStores.filter(store =>
                !isNaN(parseFloat(store.lat)) && !isNaN(parseFloat(store.lng))
            );
            if (matchingStores.length > 0) {
              this.$nextTick(() => {
                this.addMarkers();
                this.fitBounds(matchingStores);
              });
            } else {
              this.centerMapOnDefaultLocation();
              this.addMarkers(); // Show all markers
              this.showNoStoresFoundMessage();
            }
          }

          this.scrollToMap();

          this.$nextTick(() => {
            this.addMarkers();
          });
        },

        resetSearch() {
            this.searchQuery = '';
            this.handleSearch();
        },

        showNoStoresFoundMessage() {
          // You can implement this method to show a message to the user
          // For example, you could set a data property like this:
          this.noStoresFoundMessage = 'No stores found. Showing all locations.';
          // Then, after a few seconds, clear the message:
          setTimeout(() => {
            this.noStoresFoundMessage = '';
          }, 3000);
        },

        centerMapOnDefaultLocation() {
            if (this.map) {
                this.map.setCenter(this.userCoordinates);
                this.map.setZoom(6);
            }
        },

        toggleHighlight(marker) {
          // Remove highlight from all markers
          this.markers.forEach(m => {
            m.content.classList.remove("highlight");
            m.zIndex = null;
          });

          // Add highlight to the clicked/touched marker
          marker.content.classList.add("highlight");
          marker.zIndex = 1;
        },
        // highlightStoreMarker(store) {
        //     this.markers.forEach(marker => {
        //         if (marker.store.company === store.company) {
        //             this.toggleHighlight(marker, store);
        //         } else {
        //             // Remove highlight from other markers
        //             marker.content.classList.remove("highlight");
        //             marker.zIndex = null;
        //         }
        //     });
        // },
        fitBounds(stores) {
          if (this.map && this.LatLngBounds) {
            const bounds = new this.LatLngBounds();
            let validStoresCount = 0;

            stores.forEach(store => {
              const lat = parseFloat(store.lat);
              const lng = parseFloat(store.lng);
              if (!isNaN(lat) && !isNaN(lng)) {
                bounds.extend({ lat, lng });
                validStoresCount++;
              }
            });

            if (validStoresCount === 0) {
              console.log('No valid coordinates found');
              return;
            }

            if (validStoresCount === 1) {
              // For a single store, zoom in closely
              const store = stores[0];
              const storeLocation = {
                lat: parseFloat(store.lat),
                lng: parseFloat(store.lng)
              };
              setTimeout(this.smoothPanAndZoom(storeLocation, 17),10000)
              // this.smoothPanAndZoom(storeLocation, 17);
              this.openStoreModal(store);
              // Simulate a click on the marker
              this.$nextTick(() => {
                const marker = this.markers.find(m => m.store.id === store.id);
                if (marker) {
                  this.toggleHighlight(marker, store);
                }
              });
            } else {
              // For multiple stores, fit bounds with padding
              this.map.fitBounds(bounds, {
                padding: { top: 100, right: 100, bottom: 100, left: 100 }
              });

              // Adding a listener for when the bounds_changed event is fired
              // eslint-disable-next-line no-undef
              google.maps.event.addListenerOnce(this.map, 'bounds_changed', () => {
                // Get the current zoom level
                let currentZoom = this.map.getZoom();

                // Decrease the zoom level by 0.5 to zoom out slightly
                this.map.setZoom(currentZoom - 0.5);
                console.log(currentZoom - 1);

                // Ensure the zoom level doesn't go below a minimum value
                if (this.map.getZoom() < 3) {
                  this.map.setZoom(3);
                }
              });
            }
          }
        },

        smoothPanAndZoom(location, targetZoom) {
          const currentZoom = this.map.getZoom();
          const currentCenter = this.map.getCenter().toJSON();

          // First, smoothly pan to the new location
          this.smoothPan(currentCenter, location, () => {
            // After panning, smoothly zoom to the target zoom level
            this.smoothZoom(currentZoom, targetZoom);
          });
        },

        smoothPan(from, to, callback) {
          const steps = 50; // Number of steps for the animation
          let count = 0;

          const tick = () => {
            count++;
            const progress = count / steps;
            const lat = from.lat + (to.lat - from.lat) * progress;
            const lng = from.lng + (to.lng - from.lng) * progress;
            this.map.panTo({lat, lng});

            if (count < steps) {
              requestAnimationFrame(tick);
            } else {
              callback();
            }
          };

          tick();
        },

        smoothZoom(from, to) {
          const steps = 15; // Number of steps for the animation
          let count = 0;

          const tick = () => {
            count++;
            const progress = count / steps;
            const zoom = from + (to - from) * progress;
            this.map.setZoom(zoom);

            if (count < steps) {
              requestAnimationFrame(tick);
            }
          };

          tick();
        },

        // buildContent(store) {
        //     const content = document.createElement("div");
        //
        //     content.classList.add("store");
        //     content.innerHTML = `
        //         <div class="logo">
        //              <img src="${this.getImageOrPlaceholderUrl(store.logo)}" width="50" height="50" alt="${store.company || 'Store'} logo">
        //         </div>
        //         <div class="details">
        //             <div class="company">${store.company || 'Unnamed Store'}</div>
        //             <div class="info">
        //                 ${store.street ? `${store.street} ${store.street_number || ''}` : 'Address not available'}<br>
        //                 ${store.zipcode && store.place ? `${store.zipcode} ${store.place}` : ''}<br>
        //                 ${store.phone ? `<a href="tel:${store.phone}" class="btn btn-sm btn-primary">${store.phone}</a>` : 'Phone not available'}
        //             </div>
        //         </div>
        //     `;
        //     return content;
        // },

      buildContent(store) {
        const content = document.createElement("div");
        content.innerHTML = `
            <img src="${require('@/assets/images/storelocator/pin.svg')}" width="50" height="50" alt="${store.company || 'Store'}">
        `;

        const handleMarkerInteraction = (event) => {
          event.stopPropagation();
          event.preventDefault();
          this.openStoreModal(store);
        };

        // Add both click and touch event listeners
        content.addEventListener('click', handleMarkerInteraction);
        content.addEventListener('touchend', handleMarkerInteraction);

        return content;
      },

      openStoreModal(store) {
            // Remove existing modal if any
            const existingModal = document.getElementById('storeModal');
            if (existingModal) {
              existingModal.remove();
            }

            // Create modal content
            const modalContent = `
            <div class="modal fade" id="storeModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-bottom">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row align-items-center">
                                <div class="text-center mb-3 col-4">
                                    <img src="${this.getImageOrPlaceholderUrl(store.logo)}" width="100" height="100" alt="${store.company || 'Store'} logo" class="img-fluid">
                                </div>
                                <p class="col-8">
                                    <strong>${store.company || 'Store Details'}</strong><br>
                                    ${store.street ? `${store.street} ${store.street_number || ''}<br>` : ''}
                                    ${store.zipcode && store.place ? `${store.zipcode} ${store.place}<br>` : ''}
                                </p>
                            </div>
                            <p>
                                ${store.phone ? `<a href="tel:${store.phone}" class="btn btn-primary btn-sm text-white mb-2">${store.phone}</a>` : ''}<br>
                                ${store.email ? `<a href="mailto:${store.email}" class="btn btn-secondary btn-sm text-white">Write email</a>` : ''}
                            </p>
                            ${store.description ? `<hr class="mb-3"> <div>${store.description}</div>` : ''}
                        </div>
                    </div>
                </div>
            </div>
        `;

        // Add modal to the map container
        const mapContainer = document.querySelector('.map-container');
        mapContainer.insertAdjacentHTML('beforeend', modalContent);

        // Initialize and show the modal
        const modalElement = document.getElementById('storeModal');
        const modal = new Modal(modalElement, {
          backdrop: true
        });
        modal.show();

        // Remove the modal from DOM after it's hidden
        modalElement.addEventListener('hidden.bs.modal', function () {
          this.remove();
        });

        // Close the modal when clicking outside the modal-dialog div
        modalElement.addEventListener('click', function (event) {
            if (event.target === modalElement) {
                modal.hide();
            }
        });
      },

      debounce(func, wait) {
        return (...args) => {
          clearTimeout(this.searchDebounce);
          this.searchDebounce = setTimeout(() => {
            func.apply(this, args);
          }, wait);
        };
      },

      handleSearchDebounced: function() {
        this.debounce(this.handleSearch, 300)();
      },

      // New method for scrolling to map
      scrollToMap() {
        const mapElement = document.getElementById('map');
        if (mapElement) {
          mapElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        // Optionally, we can also adjust the map's center or zoom here if needed
        // For example:
        // this.map.setCenter(this.center);
        // this.map.setZoom(8);
      },

      handleInputFocus() {
        // Ensure the input is focused
        this.$refs.searchInput.focus();

        // Scroll to the map
        this.scrollToMap();
      },

    },
};
</script>

<style>
.modal {
  z-index: 10000; /* Ensure it's above other elements */
}

.modal-backdrop {
  z-index: 9999; /* Just below the modal */
}

.modal::before {
  content: none !important;
}

.modal {
  background-color: transparent !important;
}


.dashboard-storelocator .modal .modal-dialog-bottom {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) !important;
    translate: 0 !important;
    margin: 0;
    width: 90%;
    max-width: 500px; /* Adjust this value as needed */
}

.dashboard-storelocator .modal .modal-content {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
}


/* Optional: Add a subtle animation for the modal */
@keyframes slideUp {
  from { transform: translate(-50%, 100%); }
  to { transform: translate(-50%, 0); }
}

.modal.show .modal-dialog-bottom {
  animation: slideUp 0.3s ease-out;
}

.map-container {
    height: 100vw;
    position: relative;

    .map-header {
        display: none;
        height: 50px;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;

        .h3 {
            margin: 0;
            padding: 0 20px;
        }

        .btn-close {
            background-size: 1.5em;
            padding: 18px;
        }
    }

    .map-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &.map-fullscreen {
        z-index: 9999;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;

        .map-overlay {
            display: none;
        }

        .map-header {
            display: flex;
        }

        #map {
            height: calc(100% - 50px) !important;
        }
    }
}

.store {
    position: relative;
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    color: #263238;
    display: flex;
    font-size: 14px;
    gap: 15px;
    justify-content: center;
    padding: 4px;
    transition: all 0.3s ease-out;
    height: 40px;
    width: 40px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    min-width: 40px;
    min-height: 40px;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
    }

    &::after {
        border-left: 9px solid transparent;
        position: absolute;
        border-right: 9px solid transparent;
        border-top: 9px solid #fff;
        height: 0;
        left: 50%;
        top: 95%;
        transform: translate(-50%, 0);
        transition: all 0.3s ease-out;
        width: 0;
        z-index: 1;
        content: "";
    }

    .logo {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
        }
    }

    .details {
        display: none;
        flex-direction: column;
        flex: 1;
    }

    .info {
        color: #9e9e9e;
        font-size: 12px;
        margin-bottom: 10px;
        margin-top: 5px;
    }
}
</style>

<style scoped>
.empty-state {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
}

.search-container {
  position: absolute;
  left: 50%;
  z-index: 1;
  width: calc(100% - 20px);
  max-width: 380px;
  transform: translateX(-50%) translateY(-50%);
  bottom: 0;
  background-color: #fff;
  padding: 0.8em;
    border-radius: 10px;
  .h3{
    text-align: center;
    color: #e63946;
  }
}

.no-stores-found-message {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 0 0 4px 4px;
  text-align: center;
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  margin-top: 5px;
}

</style>
