// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import DashboardPage from '@/views/page/DashboardPage.vue'
import LoginPage from '@/views/page/LoginPage.vue'
import ProfilePage from '@/views/page/ProfilePage.vue'
import store from '../store'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: DashboardPage,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/profile',
        name: 'Profil',
        component: ProfilePage,
        meta: { requiresAuth: true }
    }
    // Add more routes as needed
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // Always scroll to the top when navigating to a new route
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !store.state.token) {
        next({ name: 'Login' })
        return
    }
    next()
})

export default router
